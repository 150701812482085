<template>
  <tr class="cursor-pointer" data-test-line @click="showOrder">
    <td>
      <strong>#{{ entry.uid }}</strong>
      <small v-if="entry.outletName" class="d-block">
        {{ entry.outletName }}
      </small>
    </td>
    <td>
      {{ entry.status | cfStatusBeautify(entry.ordering_service) }}
      <small
        v-if="showRejectedReason"
        class="d-block"
        data-test="reject-reason"
      >
        {{ $t(`order_reject_reasons.${entry.reason_reject}`) }}
      </small>
    </td>
    <td>
      <span v-if="customer.name">{{ customer.name }}</span>
    </td>
    <td>
      {{ customer.phone_number }}
    </td>
    <td>{{ $t(`label.${orderingMode}`) }}</td>
    <td>
      {{ getPaymentLabel(entry.payment_method, entry.ordering_service) }}
    </td>
    <Money tag="td" :amount="entry.total" :business-id="business.id" />
    <td>{{ entry.channel | cfStringBeautify }}</td>
    <td>
      {{ entry.created_at | date("DD MMM YYYY") }}
      <small class="d-block">
        {{ entry.created_at | date("hh:mm a") }}
      </small>
    </td>
    <td>
      <span v-if="entry.rating > 0">
        <StarSVG v-for="n in entry.rating" :key="n" class="w-15" />
      </span>
    </td>
  </tr>
</template>

<script>
import Order from "@/modules/order-card/Index.vue";
import Money from "@/components/helpers/Money.vue";
import StarSVG from "@/assets/svg/icons/star.svg";
import { date } from "@/utils/filters";
import { mapGetters } from "vuex";
import {
  OrderingModes,
  RejectReasons,
  Statuses,
  PaymentMethods,
} from "@/modules/order-card/enums";
import { propOr, propEq, find } from "ramda";

export default {
  name: "ListLine",
  filters: {
    date,
  },
  components: {
    Money,
    StarSVG,
  },
  props: {
    entry: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      businesses: "businesses/getData",
      outlets: "outlets/getData",
    }),
    customer() {
      return propOr({}, "customer", this.entry);
    },
    business() {
      return find(propEq("id", this.entry.business_id), this.businesses);
    },
    orderingMode() {
      if (this.entry.ordering_service === OrderingModes.DINE_IN) {
        return "dine_in";
      }
      return this.entry.ordering_service;
    },
    showRejectedReason() {
      const rejections = Object.values(RejectReasons);

      return (
        this.entry.status === Statuses.REJECTED &&
        rejections.includes(this.entry.reason_reject)
      );
    },
  },
  methods: {
    showOrder() {
      this.$modal.show(
        Order,
        { orderId: this.entry.id, businessId: this.entry.business_id },
        {
          name: "order",
          height: "auto",
          width: "100%",
          maxWidth: 600,
          adaptive: true,
        }
      );
    },
    getPaymentLabel(paymentType, orderType) {
      const mapPickupLabels = {
        [PaymentMethods.CARD_DELIVERY]: this.$t("label.card_pickup"),
        [PaymentMethods.CASH_DELIVERY]: this.$t("label.cash_pickup"),
        [PaymentMethods.CARD_ONLINE]: this.$t("label.card_online"),
        [PaymentMethods.APPLE_PAY]: this.$t("label.card_online"),
        [PaymentMethods.SKIPCASH]: this.$t("label.card_online"),
      };

      const mapOtherLabels = {
        [PaymentMethods.CASH_DELIVERY]: this.$t("label.cash_delivery"),
        [PaymentMethods.CARD_DELIVERY]: this.$t("label.card_delivery"),
        [PaymentMethods.CARD_ONLINE]: this.$t("label.card_online"),
        [PaymentMethods.APPLE_PAY]: this.$t("label.card_online"),
        [PaymentMethods.SKIPCASH]: this.$t("label.card_online"),
      };

      return orderType === OrderingModes.PICKUP
        ? mapPickupLabels[paymentType]
        : mapOtherLabels[paymentType];
    },
  },
};
</script>
